// Module: currencySelect
// Author: Matt Cavanagh
// Company: The Hut Group
// Date: January 2014
// = = = = = = = = = = = =
//
// Rationale: Creating a state-agnostic currency widget, that is flexible enough to be used now with multiple sites and templates
// whilst being modular enough that we can extend it in the future to include changes to our codebase.
//
// Status: Right now that is coupled (tightly) to an implicit structure that can be found in the nav headers JSP templates.
// A fragment in COMMON/INCLUDES/SELECTORS/ will give you an indication of the model that's required in the HTML.
//
// Usage:
//  You need to cast a new variable to the class below (never invoke or modify it directly) by using the 'new' keyword.
//  Eg: var myCurrencySelectorInstance = new CurrencySelection(args);
//
//  Where:  args can be empty (defaults from config are loaded below)
//      eg: arg
//
//  args can be a selector string (jQuery or QuerySelectorAll style) for the container
//      eg: a = new CurrencySelection('#myElem');
//
//  args can be an explicit reference to the HTML container
//      eg: a = new CurrecySelection( document.getElementByID('myElem') );
//
//  args can be an object (or an array) - and the config properties below will be overwritten by
//  the values stored in args. Any values you don't explicitly state will revert to the defaults
//
//  eg: args = {
//                    options: {
//                      horizontal : true
//                    },
//                    selectors : {
//                      container      : 'myElem',
//                      activeCurrency : '.container .currency-active',
//                      ul             :  'ul.selecty'
//                      }
//              }
//
//      ^^ In the above example, dropdown will still be set to 'select.currency-dropdown' as below.


define('CurrencySelection', ['jquery'], function($) {

  function CurrencySelection(rootElem) {
    var that = this;
    if (!(this instanceof CurrencySelection)) {
      throw new TypeError('EventCountdown must be initialized from the \'new\' keyword');
    }
    that.config = {};
    that.config.selectors = {
      activeCurrency: 'a.currency-active',
      container: 'ul.nav-items li.currency',
      dropdown: 'select.currency-dropdown',
      ul: 'ul.currency-dropdown'
    };
    that.config.options = {
      horizontal: false
    };
    that.config.elems = {};
    that._ev = {};
    that._ev.btnTouchStart = function(e) {
      e.preventDefault();
      if (that.config.elems.ul) {
        $(that.config.elems.ul).toggleClass('hidden');
        $(that.config.elems.ul).focus();
        $(that.config.elems.ul).trigger('click');
      }
    };

    that._ev.btnMouseUp = function(e) {
      $(that.config.elems.ul).toggleClass('hidden');
    };
    that._ev.btnMouseEnter = function(e) {
      $(that.config.elems.root).on('mouseleave', that._ev.containerMouseLeave);
      if ($(that.config.elems.ul).hasClass('hidden')) {
        $(that.config.elems.ul).toggleClass('hidden');
      }
    };
    that._ev.containerMouseLeave = function(e) {
      if (!($(that.config.elems.ul).hasClass('hidden'))) {
        $(that.config.elems.ul).addClass('hidden');
      }
    };
    that._ev.ulMouseEnter = function(e) {
      $(that.config.elems.root).on('mouseleave', that._ev.containerMouseLeave);
    };
    that._ev.ddFocus = function ddf(e) {};
    that._ev.ddChange = function ddc(e) {
      $(that.config.elems.ul).find('option:selected').attr('value');
      // Navigate to either ?currencySelection=currencyCode or &currencySelection=currencyCode depending on if there's other url-GET style params set
      window.location.href = ((window.location).indexOf('?') > -1) ? window.location.href += '&' : window.location.href + '?' + 'currencySelect=' + $(that.config.elems.ul).find('option:selected').attr('value');
    };

    // Figure out what sort of input we're dealing with in the constructor.
    switch ((typeof rootElem).toLowerCase()) {
      case 'undefined':
        // No configuration passed in the constructor - Defaults will be used
        break;
      case 'object':
        $.each(rootElem, function(key, value) {
          that.config[key] = rootElem[key];
        });
        break;
      case 'string':
        that.config.selectors.container = rootElem;
        break;
      case 'htmlelement':
        that.config.elems.root = rootElem;
        break;
    }


    // Populate config.elems with the HTMLElements if they're not already defined :: NB - .get(0) returns the HTMLElement, NOT the jQuery obj reference.
    if (typeof that.config.elems.root === 'undefined') {
      that.config.elems.root = $(that.config.selectors.container).get(0);
    }
    // Shim the root into a more readable variable
    var currencyContainer = that.config.elems.root;

    if (typeof that.config.elems.activeCurrency === 'undefined') {
      that.config.elems.activeCurrency = $(currencyContainer).find(that.config.selectors.activeCurrency).get(0);
    }
    var currencyButton = that.config.elems.activeCurrency;

    if (typeof that.config.elems.dropdown === 'undefined') {
      that.config.elems.dropdown = $(currencyContainer).find(that.config.selectors.dropdown).get(0);
    }
    var dropDown = that.config.elems.dropdown;

    if (typeof that.config.elems.ul === 'undefined') {
      that.config.elems.ul = $(currencyContainer).find(that.config.selectors.ul).get(0);
    }
    var ul = that.config.elems.ul;

    // Vertical Currency Selector Style begins here
    if (!that.config.options.horizontal) {
      $(currencyButton).on('touchstart', that._ev.btnTouchStart);
      $(currencyButton).on('click', function(that) {
        return false;
      });
      $(currencyButton).on('mouseup', that._ev.btnMouseUp);
      $(currencyButton).on('mouseenter', that._ev.btnMouseEnter);
      $(ul).on('mouseenter', that._ev.ulMouseEnter);
      $(dropDown).on('focus', that._ev.ddFocus);
    } else {
      // TODO: Integrate Horizontal Event Handling (if its ever required)
      ///////////////////////////////////////////////////////////////////
    }
  }
  return CurrencySelection;
});
